@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

body{
  font-family: 'Poppins', sans-serif;
  }
  .webkit{
      text-align: -webkit-center;
  }
  img{
      object-fit: cover;
      width: 100%;
  }

  /* footer */
  .icon{
    font-size: 50px;
  }
  .iconTwiter{
    color: #00ACF3;
    background: white;
    padding: 11px;
    border-radius: 39px;
  }
  .iconInsta{
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    padding: 11px;
    border-radius: 39px;
  }
  .iconTwiter{
    color: #00ACF3;
    background: white;
    padding: 11px;
    border-radius: 39px;
  }
  .iconTwiter{
    color: #00ACF3;
    background: white;
    padding: 11px;
    border-radius: 39px;
  }
  /* footerEnd */

/* main Home */
.bg{
  background: linear-gradient(45deg, #6FC067, #61C26F);
}
.wrapper {
  text-align: -webkit-center;
}

.label {
  font-size: .625rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: +1.3px;
  margin-bottom: 1rem;
}

.searchBar {
  width: 630px;
  padding-left: 13px;
  border-radius: 6px;
  background: white;
  text-align: -webkit-center;
  display: inline-flex;
  align-items: center;
}
.flex-section {
  text-align: center;
}
.flex-section--content {
  width: 100%;
}

@media screen and (min-width: 1096px){
.flex-section--content {
  max-width: 1400px;
  display: block;
}}
.flex-section--content {
  max-width: 100%;
  display: inline-block;
  margin: 0 auto;
}
.drop .dropdown{
  padding-right: 20px;
}
#searchQueryInput {
  height: 2.3rem;
  background: rgba(255, 255, 255, 0);
  outline: none;
  border: none;
  padding: 1px 3.5rem 1px 1.5rem;
  font-size: 1rem;
  border-left: 2px solid #939598;
  border-radius: 0px;
}
.dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
}

.dropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}
@media screen and (min-width: 480px){
 .section--tags .flex-section--carousel.button-list {
    justify-content: center;
}
}
@media only screen and (max-width: 992px) {
  .searchBar{
    width: auto !important;
  }
}
.section--tags .flex-section--carousel.button-list {
  gap: 10px;
  justify-content: center;
}
@media screen and (min-width: 1096px){
 .section--tags .flex-section--carousel {
    justify-content: center;
}}
.circle{
  height: 160px;
  width: 160px;
  border-radius: 300px;
  border: 3px solid #E6E7E8;
}
 .flex-section--carousel {
    margin-top: 10px;
}
.flex-section--carousel {
    display: flex;
    align-items: flex-start;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
}
.flex-section--content>* {
    padding: 0 20px;
}
  .button--light {
  padding: 0 10px;
}

 /* a {
  color: #fff;
} */
.button-list .button {
  align-items: center;
  display: flex;
  white-space: nowrap;
  gap: 5px;
}
.button-list .button svg{
  font-size: 15px;
}
html .semibold {
  font-weight: 600;
}
.button--light {
  background: #BCBEC0;
  color: #f8fafb;
  border-radius: 5px;
}
.button--xs {
  padding: 0 20px;
  min-width: 24px;
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}
.drop .dropdown-menu{
  inset: 0px auto auto -15px !important;
}
/* main Home End */

/* createAnAccount start */
.bg-acc{
  background-color: #CFEDD3;
}
.ddmm{
  justify-content: center;
  gap: 11px;
}
.clacla {
  /* margin-block-end: auto; */
    /* padding-bottom: 25px; */
    box-shadow: 0px 0px 5px 4px #8e8e8e;
    background: white;
}
.react-tabs__tab:focus:after{
  content: none !important;
}
.toggle-btn {
  display: none;
}

@media (max-width: 768px) {
  .toggle-btn {
    display: block;
  }
}
.ui.main.grid {
  left: 18.75%;
  position: relative;
  width: 81.25%;
}


/*// Semantic UI has these classes, however they're only applicable to
// grids, containers, rows and columns.
// plus, there isn't any `mobile hidden`, `X hidden` class.
// this snippet is using the same class names and same approach
// plus a bit more but to all elements.
//
// see https://github.com/Semantic-Org/Semantic-UI/issues/1114*/

/* Mobile */
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large screen only"]:not(.computer),
  [class*="widescreen only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large screen"]),
  [class*="tablet only"]:not([class*="large screen"]),
  [class*="computer only"]:not([class*="large screen"]),
  [class*="large screen hidden"],
  [class*="widescreen only"]:not([class*="large screen"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen"]),
  [class*="tablet only"]:not([class*="widescreen"]),
  [class*="computer only"]:not([class*="widescreen"]),
  [class*="large screen only"]:not([class*="widescreen"]),
  [class*="widescreen hidden"],
  [class*="widescreen or lower hidden"] {
    display: none !important;
  }
}
@media only screen and (max-width: 992px){
  .react-tabs{
    
    display: contents !important;
  }
  .react-tabs__tab{
  font-size: 12px;
}
.react-tabs__tab--selected{
  font-size: 12px;
}
  .text{
    font-size: 25px;
  }
  .react-tabs__tab-list{
    margin-bottom: 20px;
    /* width: 137px; */
  }
  .ddmm{
    display: flow-root;
    text-align: -webkit-center;
  }
  .ddmm h2{
    font-size: 18px;
    margin-bottom: 20px;
  }
}
.btnMain {
  background-color: #1E75BB;
  border: 2px solid #1E75BB;
}
.btnMain:hover{
  background: none;
  color: #1E75BB;
  font-weight: 500;
  border: 2px solid #1E75BB;
}
.btnBan {
  background-color: #1E75BB;
  border: 2px solid #1E75BB;
}
.btnBan:hover{
  background: none;
  color: white;
  font-weight: 500;
  border: 2px solid white;
}
.dflex :nth-child(2) .btn{
  background-color: #3BB44A;
  border: 2px solid #3BB44A;
}
.dflex :nth-child(2) .btn:hover{
  background: none;
  color: white;
  font-weight: 500;
  border: 2px solid white;
}
/* createAnAccount End */
#page-wrap {
  text-align: center;
  overflow: auto;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}
.react-tabs{
  display: flex;
    color: black;
}
.react-tabs__tab-list{
  /* background: url(Components/Assets/bgtabs.png); */
  /* background-size: contain; */
  /* background-repeat: round; */
  display: flex;
    flex-direction: column;
    text-align: left;
    /* width: 170px; */
    border-bottom: none !important;
    margin: 0;
    /* height: 865px; */
    padding: 0;
    color: white;
}
.react-tabs__tab {
  
  /* height: 70px; */
  list-style: none !important;
  padding: 16px 21px;
  cursor: pointer;
  color: #333333;
  /* border: 1px solid #898B8E; */
}
.react-tabs__tab--selected {
  padding-left: 21px;
}

.react-tabs__tab--selected {
  background: #333333c4;
  border-color: #333333c4;
  /* border-left: 4px solid #FD7164; */
  color: white;
}
.bm-burger-button {
  position: fixed;
    width: 36px;
    height: 30px;
    left: 13px;
    top: 139px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/* trendingProducts Start */
.bg-trend{
  background-color: #F2F3F7;
}
.MainBox{
  height: 400px;
  align-items: center;
    display: inline-flex;
    justify-content: center;
}

.hr{
  /* opacity: 0.5; */
  border: 1px solid black;
}
.mainB1{
    height: 200px;
    align-items: center;
    background: white;
    display: flex;
    justify-content: center;
    text-align: -webkit-center;
    flex-direction: column;
}
@media only screen and (max-width: 992px){
  .brde{
  border-left: 1px solid;
}
}
.brde{
  border-top: 1px solid black;
    border-bottom: 1px solid;
}
/* trendingProducts End */

/* topExporter start */
.inline{
  display: contents;
  gap: 40px;
}
.gap{
  gap: 30px;
}
@media only screen and (max-width: 992px){
  
  .inline{
    gap: 66px;
    align-items: center;
    margin-top: 35px;
    display: inline-flex;
  }
.dcont{
  display: contents;
}
/* .dcont img{
  width: 60%;
} */
}
/* topExporter End */
/* latestSec Start */
.dashed li{
  border-bottom: dashed #1E75BB;
}
.gap .inline img{
  width: 50%;
}
.box-latest{
  background-color: white;

}
/* latestSec End */

/* Login start */
.box-shad{
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 1px #cecece;;
}
.lili li{
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.form-check-input{
  border-radius: 30px !important;
}
fieldset{
  margin-top: 20px;
}
/* Login End */
/* Signup Start */
.d-flex-for-res{
  display: contents;
}
@media only screen and (max-width: 992px){
  .d-flex-for-res{
    justify-content: center;
      display: flex !important;
  }
  .react-tabs__tab img{
    width: 64% !important;
  }
  .exportC{
    gap: 8px;
    display: flex;
  }
  .exportC img{
    width: 31%;
  }
  .mm {
    width: 90% !important;
  }

  .ggp{
    gap: 15px !important;
    display: grid !important;
  }
  .btnexp{
    font-size: 12px !important;
  }
  .dflex{
    display: block !important;
  }
}
.dflex{
  display: flex;
}
.react-tabs__tab{
  color: #7A7979;
}
#tablet .react-tabs__tab--selected .mm{
  border-radius: 16px;
  border: 2px solid #1e75bb;
    background-color: #928d8d7d  !important;
}
.exportC img{
  margin-top: 5px;
}
.bg-overlay{
  height: 280px;
  background: url(Components/Assets/bg-category.png);
  display: flex;
    background-size: cover;
    /* display: grid; */
    background-repeat: round;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.btnexp{
  font-size: 12px;
  color: white;
    border-radius: 5px;
}
.ggp{
  gap: 55px;
}
#tablet .react-tabs__tab--selected .mm p{    
  color: #1e75bb !important;
  font-weight: 600;
}
#tablet .react-tabs__tab--selected img{
  object-fit: cover;
  border-radius: 17px;
  width: 80%;
}

.react-tabs__tab .mm{
  border-radius: 16px;
  border: 1px solid #6D6E71;
    background-color: #ffffff !important;
}
.react-tabs__tab img{
  width: 80%; 
}

.react-tabs__tab--selected{
font-weight: 500;
background: none !important;
border-color: none !important; 
/* text-decoration: underline; */
font-size: 25px !important;
color: #1E75BB !important;
border-radius: none !important;
}
.react-tabs__tab h5{
font-size: 18px;
}
.clacla .react-tabs__tab{
  border-bottom: 2px solid #b9b9b9!important;
  padding: 16px 12px;
}
.react-tabs__tab{
font-weight: 500;
display: inline-block;
border: none !important;

bottom: none !important;
position: relative;
padding: 16px 15px;
cursor: pointer;
font-size: 22px;
}
.css-dmmspl-MuiFormGroup-root{
  display: block !important;
}
.dddrrr{

display: flex;
float: right;
}
/* Signup End */

/* productsPage start */
.box-cate{
  border-radius: 11px;
  background: rgb(59, 180, 74);
  padding: 10px;
  padding-top: 35px;
  padding-bottom: 35px;
}
@media only screen and (max-width: 992px){

  .box-cate h2{
    font-size: 12px;
  }
  .mmd-box h3{
    font-size: 12px;
    }
    .mmd-box h4{
      font-size: 10px;
    }
    .mmd-box .inline img{
      width: 20% !important;
    }
    .ghgh img{
      width: 100%;
    }
}

.ghgh img{
  width: 70%;
}
#dropdown::after{
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.bxxx{
  background-color: #414042;
  border-radius: 10px;
  color: white;
}
.mmd-box .inline img{
  width: 15%;
}
.mmd-box h3{
  font-size: 14px;
  }
  .mmd-box h4{
    font-size: 12px;
  }
/* productsPage end */
/* exportersPage Start */
@media only screen and (max-width: 992px){
.lilili .row .col-lg-1{
  display: none;
  
}

.lilili{
  padding-top: 20px;
}
.lililill .row .col-lg-1{
  display: none;
  
}

.lililill{
  padding-top: 20px;
}
.lililill :nth-child(3){
  margin-top: 20px;
}
.box-height{
  height: auto !important;
}
}
footer .p-2 img{
  width: 60%;
}
.years-rat{
  text-align: center;
    width: 60px;
    border: 1px solid black;
    height: 60px;
    display: flex;
    padding: 10px;
    background: #1c75bc;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ddess .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button{
padding: 0px;
}
.ddess .accordion-button:focus{
  box-shadow: none;
  border-color: white;
}
.ddess .accordion-button:not(.collapsed){
  background-color: white;
}
.box-height{
  height: 300px;
}
.lililill{
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid gray;
}
.ddes li{
  margin-top: 15px;
  color: #1E75BB;
}
.ddess li{
  margin-left: 20px;
  margin-top: 15px;
  color: #1E75BB;
}

/* sidemenu */
@media screen and (max-width: 991px){
.aside-on-responsive {
    position: fixed;
    background-color: #fff;
    height: 100%;
    width: 312px;
    z-index: 1102;
    top: 0;
    bottom: 0;
    left: -312px;
}}

@media screen and (max-width: 991px){
.panel-aside, .aside-on-responsive {
    position: fixed;
    left: -100%;
    z-index: 1050;
    top: 0;
    bottom: 0;
    border-radius: 0;
}}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
#asideNav{
  transition-duration: 2s;
}
@media screen and (max-width: 991px){
.aside-content {
    /* position: absolute; */
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
    padding: 1rem;
    box-shadow: 5px 0 5px -4px rgba(200,200,200,.5);
    /* display: none; */
}
.mmx{
  display: block !important;
}
}
.mmx{
  display: none;
}